
import AuthService from "@/service/auth";
import { Options, Vue } from "vue-class-component";
declare const Buffer: {
  from: (
    data: string,
    encode: string
  ) => { toString: (encode: string) => string };
}; /// <reference types="node" /> (add this to top, npm i -D @types/node)

@Options({})
export default class UserSettings extends Vue {
  googleAuthImage = "";
  alreadySet = false;
  disabledInBe = false;

  async googleAuth() {
    await AuthService.googleAuth().then((response) => {
      this.googleAuthImage = Buffer.from(response.data, "binary").toString(
        "base64"
      );
    });
    localStorage.setItem("code", "ok");
  }

  mounted() {
    AuthService.auth(undefined, undefined).then((r) => {
      if (r.data["0-TOTP"] == true) this.alreadySet = true;
      if (r.data["0-TOTP"] == undefined) this.disabledInBe = true;
    });
  }
}
